<template>
  <div class="center">
    <router-link
      :to="{name: 'business.installs.edit', params: { id: row.id }}"
      class="btn btn-default btn-with-icon"
      title="Edit"
    >
      <div class="btn-with-icon-content"><i class="fa fa-edit"></i></div>
    </router-link>
  </div>
</template>
<script>

export default {
  props: ['row', 'nested'],
}
</script>
<style>

</style>
